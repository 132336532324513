import { createFetch } from '@vueuse/core'
import { parseDate } from './utils'
import { getEnv } from '~/env'
import { directus, myRefresh } from '~/logics/directus'
import { me } from '~/logics/store'

export const SWRVOptions = {
  refreshInterval: 60000,
}

export const deserializeDate = (key: string, value: any) => {
  if (key === 'date') {
    return parseDate(value)
  }
  return value
}

export const myUseFetch = createFetch({
  baseUrl: getEnv('SHIFTAPP_API_BASE'),
  options: {
    async beforeFetch({ options }) {
      try {
        const token = await directus.auth.token
        if (token) {
          await myRefresh()
          options.headers = { ...options.headers || {} }
          options.headers.Authorization = `Bearer ${token}`
          return { options }
        }
        else {
          return { options }
        }
      }
      catch (err) {
        console.warn('Can not refresh token in fetch', err)
        return { options }
      }
    },
    afterFetch(ctx) {
      if (ctx.response.url.endsWith('/shifts/userdata')) {
        const parsed = JSON.parse(ctx.data, deserializeDate)
        me.value = { ...me.value, ...parsed }
      }
      return ctx
    },
  },
  fetchOptions: {
    mode: 'cors',
    credentials: 'include',
  },
})

export const myUnauthorizedFetch = createFetch({
  baseUrl: getEnv('SHIFTAPP_API_BASE'),
  fetchOptions: {
    mode: 'cors',
    credentials: 'include',
  },
})

export const myFetch = async (
  key: string,
  method = 'GET',
  body: string | undefined = undefined,
) => {
  const apiBase = getEnv('SHIFTAPP_API_BASE')
  let fetched = await fetch(`${apiBase}${key}`, { method, body, credentials: 'include', headers: { Authorization: `Bearer ${(await directus.auth.token)}` } })
  if (fetched.status === 401) {
    await directus.users.me.read()
    fetched = await fetch(`${apiBase}${key}`, { method, body, credentials: 'include', headers: { Authorization: `Bearer ${(await directus.auth.token)}` } })
  }
  const text = await fetched.text()
  const parsed = JSON.parse(text, deserializeDate)

  if (fetched.url.endsWith('/shifts/userdata') && fetched.body) {
    me.value = { ...me.value, ...parsed }
  }

  if (!me.value.join_finished && me.value.id && !me.value.adressId && me.value.role === 'member') {
    window.location.href = '/join'
  }

  return parsed
}

export const directusFetch = (
  key: string,
  method = 'GET',
  body: string | undefined = undefined,
) => {
  try {
    return myUseFetch(key, { method, body })
  }
  catch (error: any) {
    if (error?.status === 403) {
      console.error('error in directusFetch', error)
    }
  }
}

export async function handleFetchError(error: any) {
  console.error('did not consume refresh token, trying to handle', error && error?.message)
  await myRefresh()
}
