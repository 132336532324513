import type { Ref } from 'vue'
import { add, isBefore } from 'date-fns'
import { getWikiShiftUrl } from '~/logics/store'
import type { PrimaryLinks, Shift, SurroundingMonth, Userdata } from '~/types'

// returns time in format HH:MM
export const shortTime = (value: Date): string => {
  return value.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
}

// returns date in format YYYY-MM-DD
export const shortDate = (value: Date): string => {
  const day = value.getDate()
  const month = value.getMonth() + 1
  const year = value.getFullYear()
  return `${year}-${month}-${day}`
}

// parse a string to a Date object
export const parseDate = (date: string): Date => {
  const parsed = (new Date(date)).getTime()
  if (!isNaN(parsed)) {
    return new Date(parsed)
  }
  return new Date(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '))
}

export const equalSet = (a: Set<any>, b: Set<any>) => a.size === b.size && [...a].every(value => b.has(value))

export const timeout = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const userOS = () => {
  if (navigator.userAgent.toLowerCase().includes('like mac')) { return 'ios' }
  if (navigator.userAgent.toLowerCase().includes('ios')) { return 'ios' }
  if (navigator.userAgent.toLowerCase().includes('mac')) { return 'mac' }
  if (navigator.userAgent.toLowerCase().includes('android')) { return 'android' }
  if (navigator.userAgent.toLowerCase().includes('x11')) { return 'unix' }
  if (navigator.userAgent.toLowerCase().includes('linux')) { return 'linux' }
  if (navigator.userAgent.toLowerCase().includes('win')) { return 'windows' }
  else { return '' }
}

/*
  Calculate the start and end of a given date's month.
*/
export const getStartAndEndMonth = (date: Date): SurroundingMonth => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1)
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  endDate.setHours(23, 59, 59, 999)
  return { start: startDate, end: endDate }
}

/*
  Calculate the start and end of a given day
*/
export const getStartAndEndDay = (date: Date): SurroundingMonth => {
  const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1)
  const endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0)
  endDate.setHours(23, 59, 59, 999)
  return { start: startDate, end: endDate }
}

/*
  Verify if first shift in array is happening now
*/
export const isWorkingShiftNow = (shifts: Array<Shift>) => {
  return isBefore(shifts[0]?.date, add(new Date(), { hours: 3 }))
}

/*
  Get users reduced motion preferences
*/
export const reduceMotion = () => {
  const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

  // Check if the media query matches or is not available.
  if (!mediaQuery || mediaQuery.matches) {
    return true
  }
  else {
    return false
  }
}

/*
  Verify if `date` is a Date object
*/
export const dateIsValid = (date: any) => {
  return typeof date === 'object'
    && date !== null
    && typeof date.getTime === 'function'
    && !isNaN(date)
}

export const openShiftProfile = async (locale: string) => {
  const url = await getWikiShiftUrl(locale)
  window.open(url[`wiki_shift_${locale}`], '_blank')
}

export function formatDaysDistance(value: Date, locale: string) {
  const deltaDays = (value.getTime() - Date.now()) / (1000 * 3600 * 24)
  const roundedDays = Math.round(deltaDays)
  const formatter = new Intl.RelativeTimeFormat(locale, { numeric: 'auto' })
  return formatter.format(roundedDays, 'days')
}

export function distanceFromNowInDays(value: Date) {
  const deltaDays = (value.getTime() - Date.now()) / (1000 * 3600 * 24)
  return Math.round(deltaDays)
}

export function filterAndSortSideBarLinks(actions: Array<PrimaryLinks>, me: Ref<Userdata>) {
  return actions
    .filter((action: PrimaryLinks) => {
      if (action.role) {
        return action.role.includes(me.value.role)
      }
      else {
        return true
      }
    })
    .sort((a: PrimaryLinks, b: PrimaryLinks) => {
      if (typeof a.position === 'number' && typeof b.position === 'number') {
        return a.position - b.position
      }
      else {
        return 0
      }
    })
}
