import { computed } from 'vue'
import { createI18n } from 'vue-i18n'
import { usePreferredLanguages } from '@vueuse/core'
import type { UserModule } from '~/types'

// import i18n resources
// https://vitejs.dev/guide/features.html#glob-import
const messages = Object.fromEntries(
  Object.entries(
    import.meta.globEager('../../locales/*.y(a)?ml'))
    .map(([key, value]) => {
      const yaml = key.endsWith('.yaml')
      return [key.slice(14, yaml ? -5 : -4), value.default]
    }),
)

const languages = usePreferredLanguages()

export const matchPreferredLanguage = computed(() => {
  const availableLanguages = Object.keys(messages)

  for (const language of languages.value) {
    const isoLanguageName = language.split('-')[0]

    if (availableLanguages.includes(isoLanguageName)) {
      return isoLanguageName
    }
  }
  return 'de'
})

export const install: UserModule = ({ app }) => {
  const i18n = createI18n({
    legacy: false,
    locale: matchPreferredLanguage.value,
    fallbackLocale: 'de',
    messages,
  })

  app.use(i18n)
}
