<script setup lang="ts">
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import iconSettingsVue from '~/components/icons/icon-settings.vue'
import iconCostVue from '~/components/icons/icon-cost.vue'
import iconAdmin from '~/components/icons/icon-admin.vue'
import iconLibrary from '~/components/icons/icon-library.vue'
import iconHelp from '~/components/icons/icon-help.vue'
import iconRecentlyViewed from '~/components/icons/icon-recently-viewed.vue'
import iconCalendarAdd from '~/components/icons/icon-calendar-add.vue'
import iconEventSchedule from '~/components/icons/icon-event-schedule.vue'
import iconBarcode from '~/components/icons/icon-barcode.vue'
import { filterAndSortSideBarLinks } from '~/logics/utils'
import { logOut, me, projectSettings, showBarcode } from '~/logics/store'
import { getEnv } from '~/env'
import type { PrimaryLinks } from '~/types'

const emit = defineEmits(['close'])
const { t, locale } = useI18n()
const router = useRouter()
const currentRoute = computed(() => {
  return router.currentRoute.value.path
})

const helpLink = computed(() => {
  try {
    return projectSettings.value[`wiki_${locale.value}`]
  }
  catch (error) {
    return 'https//wiki.robinhood.store'
  }
})

const suschiWikiUrl = computed(() => {
  if (me.value.role === 'manager') {
    return projectSettings.value?.suschi_wiki
  }
  return ''
})

const computedActions = computed(() => {
  const actions: Array<PrimaryLinks> = [
    {
      title: t('upcoming.actions.upcoming.title'),
      href: '/upcoming',
      icon: iconEventSchedule,
      iconForeground: 'text-emerald-500',
      iconBackground: 'bg-emerald-50 dark:bg-dark-900',
      position: 0,
    },
    {
      title: me.value.role === 'member' ? t('upcoming.actions.editor.title') : t('upcoming.actions.editor.title_no_member'),
      href: '/editor',
      icon: iconCalendarAdd,
      iconForeground: 'text-teal-700',
      iconBackground: 'bg-teal-50 dark:bg-dark-900',
      position: 10,
    },
    {
      title: t('upcoming.actions.suschi.title'),
      href: '/suschi',
      icon: iconCostVue,
      iconForeground: 'text-orange-600',
      iconBackground: 'bg-orange-50 dark:bg-dark-900',
      position: 20,
      role: ['manager', 'admin'],
    },
    {
      title: t('upcoming.actions.suschi_wiki.title'),
      href: suschiWikiUrl.value,
      disable: suschiWikiUrl.value === '',
      icon: iconLibrary,
      isExternal: true,
      iconForeground: 'text-orange-600',
      iconBackground: 'bg-orange-50 dark:bg-dark-900',
      position: 25,
      role: ['manager'],
    },
    {
      title: t('upcoming.actions.admin.title'),
      href: '/admin/dashboard',
      icon: iconAdmin,
      iconForeground: 'text-fuchsia-600',
      iconBackground: 'bg-pink-50 dark:bg-dark-900',
      position: 23,
      role: ['admin'],
    },
    {
      title: t('upcoming.actions.settings.title'),
      href: '/settings',
      icon: iconSettingsVue,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50 dark:bg-dark-900',
      position: 30,
    },
    {
      title: t('upcoming.actions.history.title'),
      href: '/history',
      icon: iconRecentlyViewed,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50 dark:bg-dark-900',
      position: 40,
    },
    {
      title: t('button.help'),
      inNewTab: true,
      isExternal: true,
      href: helpLink.value,
      disable: !helpLink.value,
      icon: iconHelp,
      iconForeground: 'text-purple-700',
      iconBackground: 'bg-purple-50 dark:bg-dark-900',
      position: 50,
    },
  ]
  return filterAndSortSideBarLinks(actions, me)
})

const computedSecondaryActions = computed(() => {
  const secondaryActions: Array<PrimaryLinks> = [
    {
      title: t('button.member_card'),
      href: '',
      click: () => {
        showBarcode.value = true
      },
      icon: iconBarcode,
      iconForeground: 'text-emerald-500',
      iconBackground: 'bg-emerald-50 dark:bg-dark-900',
      position: 0,
    },
  ]
  return filterAndSortSideBarLinks(secondaryActions, me)
})
</script>

<template>
  <div class="flex flex-col h-full bg-white border-r border-gray-200 dark:border-dark-300 dark:bg-dark-900">
    <div class="flex flex-col flex-none pt-5 pb-4 overflow-y-auto">
      <div class="flex items-center flex-shrink-0 px-4 space-x-2">
        <img class="w-12 h-12" width="50" height="50" :src="`${getEnv('SHIFTAPP_API_BASE')}/assets/${projectSettings?.logo}`" alt="logo">
        <span class="text-xl select-none text-emerald-500">shift</span>
      </div>
      <nav class="flex-1 px-2 mt-5 space-y-1" aria-label="Sidebar">
        <template
          v-for="item in computedActions"
          :key="item.href"
        >
          <template v-if="item.isExternal">
            <a
              :href="item.href"
              target="_blank"
              class="flex items-center px-2 py-2 text-sm font-medium no-underline rounded-md group"
              :class="[
                currentRoute === item.href
                  ? 'text-fuchsia-600 bg-gray-100 hover:bg-gray-100 dark:bg-dark-400 dark:hover:bg-dark-100'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-dark-100 dark:text-gray-200 dark:hover:text-gray-200',
              ]"
            >
              <component :is="item.icon" class="flex-shrink-0 w-6 h-6 mr-3" :class="[currentRoute === item.href ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500']" aria-hidden="true" />
              <span class="flex-1">
                {{ item.title }}
              </span>
            </a>
          </template>
          <template v-else>
            <router-link
              :to="item.href"
              class="flex items-center px-2 py-2 text-sm font-medium no-underline rounded-md group"
              :class="[
                currentRoute === item.href
                  ? 'text-fuchsia-600 bg-gray-100 hover:bg-gray-100 dark:bg-dark-400 dark:hover:bg-dark-100'
                  : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50 dark:hover:bg-dark-100 dark:text-gray-200 dark:hover:text-gray-200',
              ]"
              @click="emit('close')"
            >
              <component
                :is="item.icon"
                class="flex-shrink-0 w-6 h-6 mr-3"
                :class="[currentRoute === item.href ? 'text-fuchsia-600' : 'text-gray-400 group-hover:text-gray-500']"
                aria-hidden="true"
              />
              <span class="flex-1">
                {{ item.title }}
              </span>
            </router-link>
          </template>
        </template>
      </nav>
    </div>
    <div v-if="computedSecondaryActions.length > 0" class="flex flex-col flex-shrink-0 p-4 space-y-4 border-t border-gray-200 md:hidden dark:border-dark-300">
      <template v-for="item in computedSecondaryActions" :key="item.title">
        <div class="flex-shrink-0 block w-full p-2 no-underline rounded-md cursor-pointer group hover:bg-gray-50 dark:hover:bg-dark-100" @click="item.click">
          <div class="flex items-center">
            <div class="flex items-center justify-center text-white bg-black rounded-full dark:text-emerald-500 dark:bg-dark-900 dark:ring-2 dark:ring-emerald-500 h-9 w-9">
              <component :is="item.icon" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-200">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!-- This item tricks the following item to go to the bottom -->
    <div class="flex-1" />
    <div class="flex flex-shrink-0 p-4 border-t border-gray-200 dark:border-dark-300">
      <div class="flex-shrink-0 block w-full p-2 no-underline rounded-md cursor-pointer group hover:bg-gray-50 dark:hover:bg-dark-100" @click="logOut(router)">
        <div class="flex items-center">
          <div class="flex items-center justify-center text-white rounded-full dark:text-dark-900 bg-emerald-500 h-9 w-9">
            <span>{{ me.firstName?.slice(0, 1) }}</span>
          </div>
          <div class="ml-3">
            <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-200">
              {{ me.firstName }}
            </p>
            <p class="text-xs font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-200">
              <carbon-logout class="inline align-bottom" /> Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
