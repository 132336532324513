import { myRefresh, shouldRefreshToken } from '~/logics/directus'
import { authenticated, me } from '~/logics/store'
import { myUseFetch } from '~/logics/myFetch'

import type { UserModule } from '~/types'

export const install: UserModule = async ({ router }) => {
  router.beforeEach(async (to) => {
    window.scrollTo(0, 0)

    if (authenticated.value === undefined) {
      if (shouldRefreshToken.value) {
        try {
          await myRefresh()
          await myUseFetch('/shifts/userdata')
        }
        catch (err) {
          console.warn('Error while trying to authenticate or refresh', err)
        }
      }
      else {
        authenticated.value = true
      }
    }

    if (authenticated.value === false && !['/', '/join'].includes(to.path)) {
      return '/'
    }
    else if (authenticated.value && to.path === '/') {
      if (me.value.role === 'admin' || me.value.role === 'manager') {
        return '/suschi'
      }
      else {
        return '/upcoming'
      }
    }
  })
}
