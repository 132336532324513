import { de } from '@formkit/i18n'
import { defaultConfig, plugin } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import { theme } from '../logics/formkit-theme'
import { matchPreferredLanguage } from './i18n'
import type { UserModule } from '~/types'

// setup up formkit: https://formkit.com/essentials/installation
export const install: UserModule = ({ app }) => {
  app.use(
    plugin,
    defaultConfig({
      locales: { de },
      locale: matchPreferredLanguage.value,
      config: {
        classes: generateClasses(
          theme,
          // text: {
          //   help: 'text-xs text-gray-700',
          //   messages: 'list-none p-0 mt-1 mb-0',
          //   message: 'text-red-500 text-xs',
          //   input: 'block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm placeholder:text-xs',
          // },
        ),
      },
    }))
}
