<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { useMousePressed } from '@vueuse/core'
import { showBarcode } from '~/logics/store'
import { valueToNode } from '@babel/types';

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
    default: false,
  },
})
const emit = defineEmits(['close'])
const sidebar = ref()
const { pressed } = useMousePressed({ target: sidebar })

const xMov = ref(0)

const release = ref(false)
const dragHandler = ({ direction: [x, y], movement, distance, dragging, velocity }) => {
  if (!dragging && ((x < -0.2 && distance > 100) || velocity > 1.5)) {
    emit('close')
    return
  }
  xMov.value = movement[0]
}

watch(pressed, () => {
  if (!pressed.value) {
    xMov.value = 0
  }
})

const closeSidebar = () => {
  if (!showBarcode.value) {
    emit('close')
  }
}

const leftPosition = computed(() => {
  const value = xMov.value < 0 ? xMov.value : 0
  return Math.round(value)
})
</script>

<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="fixed inset-0 z-30 overflow-y-auto select-none" @close="closeSidebar">
      <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay
            :style="pressed ? `opacity:${1 - (-1 * leftPosition) / 500}` : 'transition-opacity'"
            class="fixed inset-0 bg-gray-500/75 dark:bg-dark-900/75"
          />
        </TransitionChild>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 -translate-x-full" enter-to="opacity-100 translate-x-0" leave="ease-in duration-200" leave-from="opacity-100" leave-to="-translate-x-full">
          <div
            ref="sidebar"
            v-drag="dragHandler"
            :style="pressed ? `transform: translateX(${leftPosition}px)` : ''"
            :class="[pressed ? '' : 'ease-in-out duration-300 transition-all transform']"
            class="will-change fixed top-0 left-0 z-30 w-64 h-full max-w-[90%] overflow-auto bg-white dark:bg-dark-900"
          >
            <div class="h-full min-h-0 ">
              <SideBarContents @close="emit('close')" />
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
