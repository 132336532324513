<script setup lang="ts">
import { ref, computed } from 'vue'
import { modalProps, me } from '~/logics/store'
import { useWindowSize } from '@vueuse/core'

const { width } = useWindowSize()
const allowToggleMenu = computed(() => {
  return width.value < 1024
})
const sideOpen = ref(false)
</script>

<template>
  <FreeButton v-if="allowToggleMenu" :open="sideOpen" @open="sideOpen = true" />
  <SideBar v-if="allowToggleMenu" :open="sideOpen" @close="sideOpen = false" />
  <!-- Layout Content without mobile menu -->
  <div :class="[allowToggleMenu ? '' : 'flex flex-col absolute inset-0']">
    <SideBarBigScreen v-if="!allowToggleMenu" class="fixed inset-y-0 w-64" />
    <div class="relative min-h-full mb-12 lg:ml-64">
      <main class="lg:absolute max-w-screen-lg max-h-full inset-0 px-2 pt-2 lg:pt-4 !mx-auto lg:mb-0 sm:px-4">
        <router-view />
      </main>
    </div>
  </div>
  <!-- Layout Content without mobile menu -->
  <MyBarcode :barcode="me.adressId" />
  <ModalError :open="modalProps.open" :title="modalProps.title" :unsafe-message-html="modalProps.unsafeMessageHtml" @close="modalProps.open = false" />
</template>
