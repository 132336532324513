import { reactive, ref } from 'vue'
import { computedAsync } from '@vueuse/core'
import type { Ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { myUseFetch } from '~/logics/myFetch'
import { directus } from '~/logics/directus'
import type { Calendar, Userdata } from '~/types'

const meDefault = () => cloneDeep({ role: '', id: '', email: '', firstName: '', adressId: '', requiredShifts: 0, shifts: [], calendars: [], pauseable: false })
export const me = ref(<Userdata>meDefault())
export const calendars = ref([]) as Ref<Calendar[]>
export const managerCalendars = ref([]) as Ref<Calendar[]>
export const userChoices = ref(new Set()) // set with ids = set(2, 5, 6)
export const authenticated = ref()
export const userLanguage = ref('')
export const modalProps = reactive({ open: false, category: '', title: '', unsafeMessageHtml: '' })
export const showBarcode = ref(false)
export const userSettingsData = ref(undefined)
export const isHiddenTooltip = ref(false)
export const wikiShiftUrl = ref([])

export const getWikiShiftUrl = async (locale: string) => {
  if (wikiShiftUrl.value.length > 0) {
    return wikiShiftUrl
  }
  else {
    const url = await directus.items('shift_app_settings').readOne(1,
      {
        fields: [`wiki_shift_${locale}`],
      })
    wikiShiftUrl.value = url
    return url
  }
}

export const getUserSettings = async () => {
  // FIXME: wait for me.value until resolving anything that uses me.value
  if (!me.value.id) {
    await myUseFetch('/shifts/userdata')
  }
  if (!userSettingsData.value) {
    const userSettings = await directus.items('rh_user_settings').readOne(me.value.id, { fields: ['data'] })
    userSettingsData.value = userSettings?.data
  }
  return userSettingsData.value
}

export const logOut = async (router) => {
  authenticated.value = false
  me.value = meDefault()
  await directus.auth.logout()
  router.push('/')
}

const getCalendarRaw = async (category: 'manager' | 'user') => {
  const queryCalendar = category === 'manager' ? 'managerCalendars' : 'calendars'
  if (me?.value?.[queryCalendar]?.length > 0) {
    const fetchedCalendars = await directus.items('shift_calendars').readByQuery({
      limit: -1,
      filter: {
        friendly_id: {
          _in: me?.value?.[queryCalendar] || [''],
        },
      },
    })
    if (category === 'manager') {
      managerCalendars.value = fetchedCalendars?.data
    }
    else {
      calendars.value = fetchedCalendars?.data
    }
    console.log('manager', managerCalendars.value)
  }
}

export const getCalendars = async () => {
  getCalendarRaw('user')
}

export const getManagerCalendars = async () => {
  getCalendarRaw('manager')
}

export const projectSettings = computedAsync(
  async () => {
    return await directus.items('shift_app_settings').readOne(1, { fields: ['*'] })
  },
  {},
  { lazy: true },
)
