admin:
  button:
    save:
      button: Save
  load_history:
    turn_off: Turn off historical data
    turn_on: Load historical data
button:
  back: Back
  barcode: Barcode
  calendar: Calendar
  go: Sign In
  help: Help
  home: Home
  language: Deutsch
  member_card: member card
  start: Dashboard
  toggle_langs: Change languages
calendar:
  header:
    friday: F
    monday: M
    saturday: S
    sunday: S
    thursday: T
    tuesday: T
    wednesday: W
cancel:
  text: >-
    Alright. Your shift for {shiftDate} has been cancelled. Please don't forget
    to sign up for another shift.
  title: Shift cancelled
  title_error: Shift coulnd't be cancelled
cant_modal:
  note: >-
    We very much rely on your participation. If you are not free to help out for
    an extended period, you can always change your membership model to donating
    a small fraction of your income. Please send us an email with a short note
    if you have personal reasons for not volunteering. We hope it never happens,
    but we can pause your memberships if you don't show up for too long.
  title: Choose a reason
editor:
  button:
    today: today
  next_shifts:
    more_options: More options
    no_shifts: none
    options:
      cancel: Cancel shift
      more_info: Read more about shift
      request_swap: Request swap
    title: Your next shifts
  title: Choose a shift
  your_next_shifts: Upcoming shifts
error_modal:
  button:
    continue: Continue
    submit: Ok
    title: Error
  forbidden:
    body: >-
      You are most likely not logged in anymore or your link is not valid
      anymore. Try to log in again.
    title: Access denied
help:
  title: Info
help_out:
  already_claimed:
    text: >-
      Someone nice already swooped in and took this job. Thanks for looking into
      it, too.
    title: Too late
  anonymous: Anonymous
  button:
    after_authenticate: first.
    authenticate: Authenticate
    take: Cover shift
  not_found:
    text: This request to cover a shift could not be found.
    title: Uh oh
  title: Cover {name}'s shift
help_out_shift:
  already_claimed:
    text: This shift is already complete.
  not_found:
    text: We couldn't find the shift you were looking for.
  title: Last minute shift
  you_already_claimed: You already signed up for this shift.
history:
  category:
    discount: Changed discount
    paid: Paid
    paused: Paused
    registration: Registration
    shift:cancelled: Shift cancelled
    shift:completed: Worked shift
    shift:enrolled: Signed up for shift
    shift:no-show: Shift no-show
    suspended: Membership suspended
    unpaused: Membership reactivated
    unsuspended: Membership reinstated
  info:
    discount: Changed to {info}
    paid: 'Amount: {info} €'
    paused: ''
    shift:cancelled: for a shift on {info}
    shift:completed: 'signed up: {info}'
    shift:enrolled: for a shift on {info}
    suspended: You haven't worked shifts
    unpaused: ''
history_data:
  label: History
information:
  loading: Loading ...
intro:
  bad_connection: Bad connection. Please try again.
  button:
    request-password: Request new password
    reset-password: Save password
  desc: Choose your shift
  dynamic-route: Demo of dynamic route
  hi: Hi, {name}!
  invalid_email: Email is not a valid email address.
  invalid_password: Password is not valid.
  new-password: New password
  no_password_yet: >-
    If you've been a member for some time and don't have a password yet, click
    on "Reset password" and create a new password.
  password_policy: Passwords must be at least 10 characters long.
  server_down: Most likely our servers are down.
  unknown_error: An unknown error occured.
  whats-your-email: Your Email address
  whats-your-password: Password
  wrong_credentials: Email or password are invalid.
join:
  address_card:
    city: City
    first_name: First name
    last_name: Last name
    mobile: Mobile phone
    street_address: Street and number
    zip: Postal code / ZIP
  back_to_sign_in: Log In
  button:
    below_pay:
      cancel_right: 14 days right of cancellation
    go: Next
    pay: Pay and support robinhood store
    register: Join for free
    resume: Resume registration with this account
  email_not_unique: Email already exists.
  error_button: Close
  logged_in:
    currently_logged_in: Currently logged in as
    logout: Logout
    new_account: New account without any data
  membership_card:
    membership_explanation: You'll help in the store for 3 hours a month.
    membership_options:
      options: >-
        I cannot help in the store, for example due to health reasons or a special burden in everyday life, such as caring for relatives, being a single parent, or something similar - of course, based on self-assessment. Therefore, I would like to support the project with one percent of my income per month. (Until recently, the monetary contribution was also selectable as a standard option, but we have now changed that to ensure the flourishing and growth of this idea on a more sustainable number of shoulders.)
    monthly_payment_number_error: Please enter a value higher than 7 €.
  newsletter:
    help: Etwa 4x im Jahr, zu wesentlichen und strukturellen Updates
    label: >-
      Ich möchte den Newsletter bekommen (etwa 4x im Jahr, zu wesentlichen und
      strukturellen Updates).
  or: Cancel and go back to
  password:
    error_length: Your password must be at least 10 characters long.
    help: >-
      Passwords should be unique for each site. Preferably use a password
      manager.
  question:
    credit:
      soli:
        help: >-
          Your credit helps us stay financially afloat. If you can't afford this
          expenditure, deactivate your credit with this checkbox. We'd like to
          have you, anyways.
        label: Membership without credit
        title: Solidarity Membership
      status:
        how:
          paying: >-
            We'll charge your account via XXX at the same time as we charge your
            first monthly contribution.
          working: We'll charge your account via SEPA Direct Debit Scheme.
        info: >-
          We charge your account once for 60 €. In return, you'll receive 5 € at
          the beginning of each month for the next 12 months.
        no_credit: You've decided to become a member without credit.
      title: Credit
    membership:
      monthly_payment: Monthly recurring payment in €
      monthly_payment_placeholder: Select 1% of your monthly net income
      screenreader: Choose how you want to participate.
      title: You help out in the store for three hours a month.
    payment:
      address_line_1: Street + No
      address_line_2: Appartment, c/o or others
      bic: BIC
      city: City
      country: Country
      first_name: First name
      iban: IBAN
      info: >-
        We'll charge your account monthly with {chooseMonthlyPayment} € via SEPA
        Direct Debit Scheme.
      last_name: Last name
      mobile:
        help: null
        label: Phone No
      sepa:
        help: >-
          I hereby authorize Steib, Katz, Barthel GbR to collect payments from
          my account by direct debit. I instruct my credit institution to honor
          the direct debits issued by Steib, Katz, Barthel GbR. Note: I can
          request a refund of the debited amount within eight weeks, beginning
          with the debit date. The conditions agreed upon with my credit
          institution apply. The period for advance notification of the SEPA
          direct debit is reduced to three days.
        info: >-
          robinhood store is operated by the Steib, Katz, Barthel GbR,
          Altenbraker Str. 15, 12053 Berlin; Creditor Identifier:
          DE94ZZZ00002372740
        label: |-

          Allow SEPA Direct Debit Scheme
        modal_close_button: Close
      title: Monthly contribution
      zip: Zip
    store:
      screenreader: Choose which store you want to join.
      title: Which store do you want to join?
    weserstrasse:
      info: >-
        Welcome to Weserstrasse! 🎉 We're excited to announce that our new store
        location will be opening on June 10th! As a new member, your membership
        will officially begin on the opening day, so there's no need to worry
        about it starting immediately. Rest assured, your first contribution or
        account balance withdrawal will not take place until the end of June. We
        can't wait to have you as part of our community, and we look forward to
        seeing you at the store soon! 😊
  success_button: Proceed to ShiftApp
  success_text: >-
    ⏳ Wir schicken dir gerade eine Bestätigungs-Mail.<br /> Schau dich um auf <a
    href="https://wiki.robinhood.store">wiki.robinhood.store</a> für die
    nächsten Schritte.
  summary:
    membership:
      paying:
        credit: >-
          We'll make your credit of {chooseCredit} € available in 12 monthly
          installments at our tills.
        info: >-
          We'll charge you {chooseMonthlyPayment} € each month. In return you'll
          receive 15 % discount on products in our store. Your membership will
          be extended automatically. Your discount is non-transferable. If you need more (or less) than a 15% discount, you can adjust this at any time at shift.robinhood.store.
      working:
        credit: >-
          Your credit of {chooseCredit} € will be made available at our tills in
          12 monthly installments.
        info: >-
          After working a shift in a robinhood store, you'll receive a discount
          of 15 % on your shopping value. This discount is non-transferable. If you need more (or less) than a 15% discount, you can adjust this at any time at shift.robinhood.store.
    newsletter:
      title: Newsletter
    terms_and_cancel:
      intro: 'These terms and conditions apply to your robinhood store membership:'
      privacy: Privacy Policy
      terms: General Terms and Conditions
      title: Terms of Service and Cancellation Policy
  tabs:
    account: Login
    address: Address
    membership: Membership
    payment: Payment
    verify: Summary
  title: Join Robinhood
login:
  error: ❤️‍🩹 Sorry. An error occurred. Go to
  not_valid: 🚫 Der Link ist nicht mehr gültig.
  redirecting: ' 👍 Logged in. Redirecting ...'
  success: ' 👍 Logged in. You can close this tab.'
menu_actions:
  request_double_tap: Yes, confirm
my_combo_box:
  loading: Loading
  no_results: Nothing found
  start_typing: Start typing
not-found: Not found
pagination:
  input:
    current_page: Current Page
    first_page: first page
    last_page: last page
    next_page: next page
    previous_page: previous page
planner:
  button:
    add: Add shift
    cant: I'm temporarily not available
    cant_dismiss: Undo
    save:
      button: Saves automatically
      label: Shifts
      of: of
      success: Saved
  cant:
    body: That's a pity. You can catch up again next month.
    title: This month doesn't work out?
  delete_shift:
    button: Delete
    title: Delete shift?
  intro: Click on a day and select your preferred shift.
reset_password:
  cancel: Back to login
  error-message: We couldn't find your email. You probably registered with another email.
  submit-message: >-
    If your email is know to us, we've send you an email with further
    instructions. <br /> If you didn't receive anything, please look into your
    spam folder. <br /><br /> You can close this window.
  title: Reset password
scanner:
  amount_select:
    label: new amount
    text: Please tell us how many pieces of the product you counted.
    unit: Piece
  button:
    info: finish stocktaking
    text: Confirm
  confirm_modal:
    button: Continue
    text: Stocktaking saved
    title: Thank you!
  error_modal:
    default:
      text: >-
        There has been a problem with your request...<br> please try again in a
        few seconds.
      title: Oh damn!
    scan:
      text: >-
        Unfortunately, we could not identify that barcode. <br> Please try
        again.
      title: Oh damn!
  heading:
    sub_text: Now let's continue with your stocktaking
  product_description:
    current_amount: old amount
    heading: Product Information & Confirmation
    name: product name
    sub_heading: >-
      Here you can view the information for the currently selected product and
      finish your stocktaking.
  product_select:
    ean:
      placeholder: EAN
      text: Please type in the product id.
    label: Selected Product
    product_name:
      placeholder: Product Name
      text: Please type in the product name.
  search_type_radio:
    label: Usage Mode
    options:
      ean: search for EAN code
      product_name: search for product name
      scanner: scan EAN code
    text: >-
      You can either scan a barcode or search for the product by typing in the
      EAN code or product name.
  store_select:
    label: Please select your store
    text: For what store are you creating a stocktaking?
settings:
  application:
    choose_language: Choose language
    description: Change your preferences for the robinhood application.
    title: Application
  danger:
    finalize: Yes, cancel membership
    no_danger: Save
  danger_zone:
    description: You can cancel your membership here
    sections:
      cancel_membershift:
        title: Cancel my membership
    title: Danger zone
  error_modal:
    description: >-
      An error happened while saving your settings. Feel free to reach out and
      tell us about the error. Error Code: 8420.
    title: Uh oh!
  history:
    description: See your account and community history
    hide_button: Hide entire history
    title: History
    view_button: See entire history
  input:
    calendar: Calendar
    calendar_sync:
      button_copy: Copy Calendar URL
      button_copy_done: Copied to clipboard
      description: >-
        Subscribe to your shift calendar in any calendar app on your phone and
        PC. Warning: You'll be generating a URL that you should only share with
        applications you trust.
      instructions: >-
        How to: <a
        href="https://support.google.com/calendar/answer/37100?hl=en&co=GENIE.Platform%3DAndroid"
        target="_blank">Android</a> &#124; <a
        href="https://support.apple.com/en-us/HT202361" target="_blank">Apple
        Devices</a>
      title: Calendar Sync
    cancel_membership:
      description: >-
        Please note: Per our initial agreement, robinhood store can not speed up
        the disbursement of potentially remaining credit.
      title: Cancel my membership
    change_password:
      button: Change password
      title: Password
    city:
      title: City
    country:
      title: Country
    discount:
      description: >-
        Choose a value between -20% (you pay more) and 20% (you pay less).
        Please choose a discount of more than 15% if you experience financial
        difficulties.
      notification_less_0: You're paying extra
      notification_more_15: If you experience financial challenges
      title: Discount
      validation_note: Discount should be between -20 and 20.
    email:
      cancel_change_button: Cancel change
      description:
        pending: >-
          We've sent you an email with a link to verify your new email. This
          link is valid for 24h.
        verified: >-
          We'll send you an email with a link to verify your new email. This
          link is valid for 24h.
      title: Email address
    interests:
      title: Interests
    language: Sprache
    name:
      title: Name
    password:
      description: >-
        We've send you an email with further instructions to change your
        password.
    pause:
      button: Pause membership
      description: >-
        Pausing your account means that your shift balance can't increase and
        you won't get a discount. Pause your account if you know you're out of
        town for a few months.
      description_if_no_shifts: >-
        We've paused your account because you haven't been doing shifts in
        while. After you've worked a shift you will be automatically unpaused.
        Please take into account that it can take up to 24h until you receive
        your discount again.
      description_will_auto_unpause: >-
        Your account is still paused. You can unpause your account here,
        manually – or wait until we do it automatically. Please take into
        account that it can take up to 24h until you receive your discount
        again.
      paused_status: >-
        Your account is currently paused. When you activate your account, it can
        take up to 24h until you receive your discount again.
      title: Pause
      upaused_status: >-
        You're account is currently active. Pausing your account means you won't
        get a discount in our shops.
    pause_reminder:
      description: Always notify me if I'm being paused because I haven't worked shifts.
      title: Automatic pause / activation
    phone:
      title: Cell phone number
    reminder_one_week:
      description: Remind me seven days before my next shift.
      title: 'Reminder: One week ahead'
    reminder_two_days:
      description: Remind me two days before my next shift.
      title: 'Reminder: Two days ahead'
    street:
      title: Street
    tips: Tips
    tooltip:
      description: >-
        First-time users are shown additional texts about how they can use the
        shift app.
      title: How to sign-up for shifts
    unpause:
      button: Reactivate membership
    zip:
      title: ZIP
  membership:
    description: Basic settings about your robinhood membership
    title: Membership
  memership:
    reset_button: Reset
    save_button: Save
  notifications:
    description: Decide which communications you'd like to receive.
    save_button: Save
    title: Notifications
  personal_information:
    save_button: Save
    title: Personal information
settiwgs:
  input:
    language:
      title: Language
shift_modal:
  button:
    cancel: Cancel
    close: Close
    submit: Save
  duration: 3 hours
  enroll_for_shift: Sign up for shift
  enrolled_button: Signed up
  important_shift: Important shift
  more_shift_info: Read more about shift
  other_volunteer_count: shift deleted | on your own | {n} volunteers
  swap:
    button_cancel: Cancel swap request
    button_copy: Copy Link
    help_out: Help a fellow member out and cover their shift.
    reason: 'Reason:'
    stay_put: >-
      Another member is suddenly unavailable and tries to find someone who can
      cover them. Thanks for being there.
    yours_ongoing: Other members can see your request to cover your shift at this position.
  swap_notice: A member is looking for someone to cover their shift
  title: Choose your shift for {day} – {date}
shift_profile:
  button:
    close: Close window
  text_header: 'What you''ll be doing:'
  title: 'Shift profile: {type}'
  type_not_exist: >-
    This shift doesn't have a more detailed profile yet. We'll let you in on its
    secrets once you're here.
shift_tile:
  cancel: Cancel
  description: job note
  reason: '{name} motivates the request with'
  start_swap: Start swap
signin:
  call_to_join: register and become a member
  desc: Use the email address which you used during your robinhood registration.
  or: or
  request_password: Reset password
  title: shift Sign in
sr-only:
  select:
    select: Select
    shift: Sort of work
    unavailable: Reason for unavailability
store_select:
  label: Location
  prompt: Select a store
success:
  info:
    button_editor: Edit your shifts
    late: >
      If at the very last minute you're late or can't come to your shift, please
      let us know<br />

      <b>☎️ 01775 00 74 87</b> (09:30 - 20:00)
    text_4_days: >
      <p>If you have to exchange your shift and it is more than four days in
      advance, just change it in the calendar.</p>
    text_less_days: >
      <p>Less than four days in advance, click on "Start swap" in the green
      shift tile to make the shift available for others. Try to cover the shift
      by posting the link in the Telegram group. Make the confirmations in a
      private chat.</p>

      <p>If you have been unable to cover the shift by the day before, please
      contact our "service
      hotline"&nbsp;☎️️&nbsp;030&nbsp;814&nbsp;570&nbsp;259</p>
    title: Changed plans?
  text: 'Currently no upcoming jobs. | Your upcoming job: | Your upcoming jobs:'
  text_nojobs: <p>Sometimes less is more. Come back when you change your mind. 😘</p>
  title: 🎉 Thank you,
  title_nojobs: Right on,
suschi:
  modal_day_view:
    add_member:
      placeholder: Enter a name
    slot_actions:
      cancel_shift: Delete member
      late_show: Flag late-show
      no_show: Flag no-show
      start_swap: Request swap
  no_store_error: Not enough privileges
swap:
  button:
    cancel: Cancel
    details: more
    submit: Send request
  copied:
    info: Copied link to clipboard
  modal_intro: >-
    You can't cancel a shift on short notice because shifts often stay empty
    otherwise. Please find someone to cover your shift <b>{type}</b> on
    <b>{date}</b> at <b>{time}</b>.<br /><br /> E.g. share this request in our
    <a href="https://t.me/joinchat/Ivq6LsRh2xM1YjQy" target="_blank">Telegram
    group chat</a>. 🎙
  modal_title: Request swap
  prompt_reason: Describing your situation helps others to decide.
  reason_placeholder: optional reason (max 100 characters)
  share:
    text: Ask other members if they can cover your shift on short notice
    title: Please cover my shift
tabs:
  select_prompt: Select a tab
temp:
  new_rules: >
    🎉 Better rules for working members. Read the 

    <a
    href="https://wiki.robinhood.store/English-Newsletter-September-2022-3cda160796c84c438f7c9dc2f4df346a"
    target="_blank">announcement</a>.
time:
  select:
    label: Timeslot
tooltip:
  button:
    proceed: Sure thing
  enroll_shifts:
    text: >-
      Click on a day and select your preferred shift. Each shift is represented
      with an emoji. Don't worry if you made a mistake, you can just as easily
      cancel up to 4 hours afterwards and 4 days ahead.
    title: How to get started
  header:
    friday: F
    monday: M
    saturday: S
    sunday: S
    thursday: T
    tuesday: T
    wednesday: W
  hide_future: Hide this in the future
  tabs:
    calendar_overview:
      evening: evening
      morning: morning
      noon: noon
      text: >-
        Find available shifts by looking for the three dots indicating morning,
        noon and evening. In this example, the pink dot indicates available
        shifts in the evening.
      title: Sign up for shifts
    cancel_conditions:
      4_days_ahead: 4 days ahead
      shift: Shift
      text: >-
        Cancel up to 4 days ahead of a shift. However, for short notice
        cancellations, find someone to cover your shift by requesting a swap.
      title: Cancel shifts
    pausing_conditions:
      text: >-
        Your account will stay active for 30 days after you've worked a shift.
        Work another shift before the deadline runs out to prevent an automatic
        pause.
      title: Stay active
upcoming:
  action:
    sign_up_shift: Sign up new shift
  actions:
    admin:
      description: Create, edit and delete shifts
      title: Admin
    editor:
      no_next_shift: You haven't signed up for shifts.
      prefix_distance: 'Next shift:'
      title: Shift schedule
      title_no_member: Work shifts
    history:
      title: History
    scanner:
      description: "Easy stocktaking in robinhood stores –\_help us get our inventory right"
      title: Scanner
    settings:
      description: Edit your membership settings
      title: Settings
    suschi:
      description: >-
        Get a detailed weekly overview of our shifts. You can also add and
        remove members from shifts.
      title: Manage shifts
    suschi_wiki:
      description: "\"One of the great things about books is sometimes there are some fantastic pictures.\" –\_G. W. Bush"
      title: Suschi Wiki
    upcoming:
      title: Dashboard
  barcode:
    dismiss: Press anywhere to dismiss.
    sr_only_dismiss: Press Escape to dismiss
  editor:
    next:
      day_after_tomorrow: day after tomorrow
      today: today
      tomorrow: tomorrow
  title: Upcoming
  widget_active_membership:
    active_for_days: In {distance} days.
    active_membership: Active membership
    active_until: Your account will stay active until {date}
    extra_days: >-
      After unpausing, your account will remain active for another { count }
      days.
    no_extra_days: You need to work a shift before we activate your account again.
    not_active: Your account is paused.
    paused_membership: Paused membership
    puppy_protection: >-
      You can unpause your account but finish a shift up to October 15th in
      order keep your account activated.
verify-email:
  close_prompt: You can close this window now or go to
  error: Could not verify your email address. Maybe your token is expired.
  pending: Verifying your new email address...
  settings_link: your user setings
  success: New email address successfully verified.
