<script setup lang="ts">
import { watch } from 'vue'
import { useMagicKeys } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { showBarcode } from '~/logics/store'
import encodeBarcode128 from '~/logics/encode-barcode128'

const props = defineProps({
  barcode: {
    type: String,
    required: true,
    default: '',
  },
})

const { t } = useI18n()
const { escape: escapeKey } = useMagicKeys()
watch(escapeKey, () => {
  showBarcode.value = false
})
</script>

<template>
  <div v-if="showBarcode" class="fixed inset-0 z-50 overflow-y-auto bg-white dark:bg-dark-900" @click="showBarcode = false">
    <div class="grid items-center h-screen justify-items-center">
      <div class="prose">
        <div
          class="p-2 text-center text-black bg-white rounded-md text-8xl barcode"
        >
          {{ encodeBarcode128(props.barcode) }}
        </div>
        <div class="-mt-8 text-center text-black">
          {{ props.barcode }}
        </div>
        <div class="mt-8 text-sm text-center text-gray-500 dark:text-gray-400">
          {{ t('upcoming.barcode.dismiss') }}
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.barcode {
  font-family: 'Libre Barcode 128';
}
</style>
