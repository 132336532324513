const __pages_import_0__ = () => import("/src/pages/admin/settings.vue");
const __pages_import_1__ = () => import("/src/pages/admin/easy-create.vue");
const __pages_import_2__ = () => import("/src/pages/admin/dashboard.vue");
const __pages_import_3__ = () => import("/src/pages/user-stats.vue");
const __pages_import_4__ = () => import("/src/pages/upcoming.vue");
const __pages_import_5__ = () => import("/src/pages/suschi.vue");
const __pages_import_6__ = () => import("/src/pages/settings.vue");
const __pages_import_7__ = () => import("/src/pages/scanner.vue");
const __pages_import_8__ = () => import("/src/pages/join.vue");
import __pages_import_9__ from "/src/pages/index.vue";
const __pages_import_10__ = () => import("/src/pages/history.vue");
const __pages_import_11__ = () => import("/src/pages/editor.vue");
const __pages_import_12__ = () => import("/src/pages/[...all].vue");
const __pages_import_13__ = () => import("/src/pages/user/[id].vue");
const __pages_import_14__ = () => import("/src/pages/verify-email/[token].vue");

const routes = [{"name":"admin-settings","path":"/admin/settings","component":__pages_import_0__,"props":true},{"name":"admin-easy-create","path":"/admin/easy-create","component":__pages_import_1__,"props":true},{"name":"admin-dashboard","path":"/admin/dashboard","component":__pages_import_2__,"props":true},{"name":"user-stats","path":"/user-stats","component":__pages_import_3__,"props":true},{"name":"upcoming","path":"/upcoming","component":__pages_import_4__,"props":true},{"name":"suschi","path":"/suschi","component":__pages_import_5__,"props":true},{"name":"settings","path":"/settings","component":__pages_import_6__,"props":true},{"name":"scanner","path":"/scanner","component":__pages_import_7__,"props":true},{"name":"join","path":"/join","component":__pages_import_8__,"props":true,"meta":{"layout":"center"}},{"name":"index","path":"/","component":__pages_import_9__,"props":true,"meta":{"layout":"center"}},{"name":"history","path":"/history","component":__pages_import_10__,"props":true},{"name":"editor","path":"/editor","component":__pages_import_11__,"props":true},{"name":"all","path":"/:all(.*)*","component":__pages_import_12__,"props":true,"meta":{"layout":404}},{"name":"user-id","path":"/user/:id","component":__pages_import_13__,"props":true},{"name":"verify-email-token","path":"/verify-email/:token","component":__pages_import_14__,"props":true,"meta":{"layout":"empty"}}];

export default routes;