<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useRouteQuery } from '@vueuse/router'
import { directus } from '~/logics/directus'
import { authenticated, projectSettings } from '~/logics/store'
import { getEnv } from '~/env'

const { t } = useI18n()
const router = useRouter()

/*
  NOTE: This page has three states:
  * login = default, shows an email and password field
  * request = request a password reset, shows an email field.
  * reset = reset a password, shows a "new password" field

  this status is represented by `const status = ref('login')`

  Additionally, all content is hidden from this page if message.value is anything else
  but an empty string; instead the message string will be displayed.
*/
const status = ref('login') as Ref<'login' | 'request' | 'reset'>
const message = ref('')

const email = ref('')
const password = ref('')
const loading = ref(false)
const error = ref('')
const token = useRouteQuery('token')

/*
  Display a helpful error message if credentials are wrong.
*/
const badcreds = (email: str) => {
  loading.value = false
  error.value = 'badcreds'
}

/*
  Send requests when user submits login form.
*/
async function submit() {
  loading.value = true
  if (status.value === 'login' && email.value && password.value) {
    loading.value = true
    try {
      await directus.auth.login({ email: email.value, password: password.value })
      authenticated.value = true
      router.push('/upcoming')
    }
    catch (err) {
      console.log('err', err)
      if (err.errors[0]?.extensions?.code === 'INVALID_CREDENTIALS') {
        badcreds(email.value)
      }
      else {
        error.value = 'server down'
      }
    }
  }
  else if (status.value === 'reset') {
    try {
      await directus.auth.password.reset(token.value, password.value)
      await directus.auth.login({ email: email.value, password: password.value })
      authenticated.value = true
      router.push('/upcoming')
    }
    catch (err) {
      console.error('An error occured', err)
      error.value = 'request failed'
    }
  }
  else if (status.value === 'request' && email.value) {
    try {
      await directus.auth.password.request(email.value, getEnv('SHIFTAPP_SITE_BASE'))
      message.value = `${t('reset_password.submit-message')} 📨`
    } catch (e) {
      console.error('An error occured', e)
      error.value = 'request failed'
    }
  }
  loading.value = false
}

/*
  Utility function: Decode a Directus-password-reset JWT and parse as object.
  We need an object with at least an email attribute, in case there is a bad token.
*/
function decodeToken(str: string) {
  try {
    return JSON.parse(decodeURIComponent(escape(window.atob(str.split('.')[1]))))
  }
  catch (err) {
    return { email: '' }
  }
}

/*
  Disable the login-button if the action defined in status can't be completed
*/
const buttonSubmitDisabled = computed(() => {
  if (status.value === 'login') {
    return email.value === '' || password.value === ''
  }
  else if (status.value === 'request') {
    return email.value === ''
  }
  else { // if (status.value === 'reset')
    return password.value.length < 10
  }
})

onMounted(() => {
  if (token.value) {
    status.value = 'reset'
    email.value = decodeToken(token.value).email
  }
})
</script>

<template>
  <div class="flex min-h-full">
    <div
      class="flex flex-col flex-1 px-1 sm:justify-center sm:px-6 lg:flex-none lg:px-20 xl:px-24"
      :class="[message ? 'justify-center' : 'justify-start']"
    >
      <div
        class="w-screen max-w-[20rem] mx-auto border-gray-200 rounded-xl sm:min-w-[24rem] lg:w-96 sm:shadow-lg sm:p-6"
        :class="[message ? 'border p-6' : 'sm:border p-2 sm:py-16 sm:px-12']"
      >
        <template v-if="!message">
          <div>
            <div class="flex items-center flex-shrink-0 space-x-2 select-none">
              <img
                class="w-12 h-12 transition-opacity duration-1000 ease-in"
                :class="projectSettings?.logo === undefined ? 'opacity-0' : 'opacity-100'"
                width="50"
                height="50"
                :src="`${getEnv('SHIFTAPP_API_BASE')}/assets/${projectSettings?.logo}`"
                alt="logo"
              >
              <span class="text-xl text-emerald-500">{{ status === 'login' ? t('signin.title') : t('reset_password.title') }}</span>
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <form class="space-y-4">
                <div class="-space-y-px rounded-md shadow-sm">
                  <div>
                    <label for="email" class="sr-only">{{ t('intro.whats-your-email') }}</label>
                    <input
                      id="email"
                      ref="emailRef"
                      v-model="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      :required="status !== 'reset'"
                      class="relative block w-full px-3 py-2 placeholder-gray-500 bg-transparent border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 focus:z-10 sm:text-sm"
                      :class="[status === 'request' ? 'rounded-b-md' : '']"
                      :placeholder="t('intro.whats-your-email')"
                    >
                  </div>
                  <div v-if="status !== 'request'">
                    <label for="password" class="sr-only">{{ status === 'login' ? t('intro.whats-your-password') : t('intro.new-password') }} </label>
                    <input
                      :id="status === 'login' ? 'current-password' : 'new-password'"
                      ref="passwordRef"
                      v-model="password"
                      name="password"
                      type="password"
                      :autocomplete="status === 'login' ? 'current-password' : 'new-password'"
                      required
                      class="relative block w-full px-3 py-2 placeholder-gray-500 bg-transparent border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-emerald-500 focus:border-emerald-500 focus:z-10 sm:text-sm"
                      :placeholder="status === 'login' ? t('intro.whats-your-password') : t('intro.new-password')"
                      @keydown.enter="submit"
                    >
                  </div>
                </div>
                <div v-if="status === 'reset'" class="text-xs text-gray-500">
                  {{ t('intro.password_policy') }}
                </div>

                <div class="flex items-center justify-between">
                  <div class="flex items-center" />

                  <div class="text-sm">
                    <a
                      v-if="status === 'login'"
                      href="#"
                      class="font-medium text-gray-600 no-underline dark:text-gray-400 hover:text-emerald-600"
                      @click.prevent="status = 'request'"
                    >
                      {{ t('signin.request_password') }}
                    </a>
                    <a
                      v-else-if="status === 'request'"
                      href="#"
                      class="font-medium text-gray-600 no-underline dark:text-gray-400 hover:text-emerald-600"
                      @click.prevent="status = 'login'"
                    >
                      {{ t('reset_password.cancel') }}
                    </a>
                  </div>
                </div>
                <div v-if="error === 'badcreds'" class="text-red-600">
                  {{ t('intro.wrong_credentials') }}
                </div>
                <div v-else-if="error === 'server down'" class="text-red-600">
                  {{ t('intro.server_down') }}
                </div>
                <div v-else-if="error === 'request failed'" class="text-red-600">
                  {{ t('intro.server_down') }}
                </div>

                <div>
                  <ButtonLoading
                    :loading="loading"
                    class="justify-center w-full btn"
                    :disabled="buttonSubmitDisabled"
                    @click.prevent="submit"
                  >
                    <span v-if="status === 'login'">
                      {{ t('button.go') }}
                    </span>
                    <span v-else-if="status === 'request'">
                      {{ t('intro.button.request-password') }}
                    </span>
                    <span v-else> <!-- if status === 'reset' -->
                      {{ t('intro.button.reset-password') }}
                    </span>
                  </ButtonLoading>
                </div>
              </form>
              <!-- TODO: disable this more explicitly for non-robinhood projects -->
              <p v-if="status === 'login' && (getEnv('SHIFTAPP_API_BASE').endsWith('robinhood.store') || getEnv('SHIFTAPP_API_BASE').endsWith('gitpod.io'))" class="mt-8 text-gray-600 dark:text-gray-400">
                {{ t('signin.or') }}
                <router-link to="/join" class="font-medium no-underline hover:text-fuchsia-700">
                  {{ t('signin.call_to_join') }}
                </router-link>
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-html="message" />
        </template>
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: center
</route>

<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
</style>
