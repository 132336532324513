import { computed } from 'vue'
import { Directus } from '@directus/sdk'
import { authenticated } from '~/logics/store'
import { getEnv } from '~/env'
import type { RobinhoodModel } from '~/types'

export const directus = new Directus<RobinhoodModel>(getEnv('SHIFTAPP_API_BASE'))

export const shouldRefreshToken = computed(() => {
  const authExpiresAt = directus?.storage?.auth_expires_at
  if (authExpiresAt === null) {
    return true
  }
  return Date.now() > authExpiresAt
})

const refreshAndSetAuthRef = async () => {
  try {
    const refresh = await directus.auth.refresh()
    if (refresh) {
      authenticated.value = true
    }
    else {
      authenticated.value = false
    }
  }
  catch (err) {
    console.warn('Could not refresh token', err)
    authenticated.value = false
  }
}

export const myRefresh = async () => {
  try {
    if (shouldRefreshToken.value) {
      await refreshAndSetAuthRef()
    }
  }
  catch (err) {
    // console.error('error while trying to refresh directus, error:', err)
    authenticated.value = false
  }
}
