admin:
  button:
    save:
      button: Speichern
  load_history:
    turn_off: Alte Daten ausschalten
    turn_on: Historische Daten laden
button:
  back: Zurück
  barcode: Barcode
  calendar: Kalender
  go: Anmelden
  help: Hilfe
  home: Startseite
  language: English
  member_card: Mitgliedskarte
  start: Übersicht
  toggle_langs: Sprachen ändern
calendar:
  header:
    friday: F
    monday: M
    saturday: S
    sunday: S
    thursday: D
    tuesday: D
    wednesday: M
cancel:
  text: >-
    Okidoki. Deine Schicht für {shiftDate} wurde abgesagt. Bitte vergiss
    trotzdem nicht eine neue Schicht einzuplanen.
  title: Schicht abgesagt
  title_error: Schicht konnte nicht abgesagt werden
cant_modal:
  note: >-
    Wir bauen sehr auf deine Mitarbeit. Kannst du für längere Zeit nicht
    mithelfen, freuen wir uns wenn du ein spendendes Mitglied wirst. Bitte
    schicke uns eine Nachricht wenn es persönliche Gründe für dein Wegbleiben
    gibt. Und, wir hoffen, dass es nie passiert, aber wir können deine
    Mitgliedschaft pausieren, wenn du zu lange nichts von dir hören lässt.
  title: Wähle einen Grund
editor:
  button:
    today: heute
  next_shifts:
    more_options: Mehr Optionen
    no_shifts: keine
    options:
      cancel: Schicht austragen
      more_info: Infos zur Schicht
      request_swap: Wechsel anfragen
    title: Deine nächsten Schichten
  title: Wähle eine Schicht
  your_next_shifts: Deine nächsten Schichten
error_modal:
  button:
    continue: Weiter
    submit: Ok
    title: Fehler
  forbidden:
    body: >-
      Du bist wahrscheinlich nicht mehr eingeloggt oder dein Link ist ungültig.
      Probiere dich erneut ein zu loggen.
    title: Zugriff nicht gestattet
help:
  title: Mehr Infos
help_out:
  already_claimed:
    text: >-
      Ein anderes Mitglied hat sich bereits bereiterklärt um bereitzustehen.
      Trotzdem Danke!
    title: Bereits vergeben
  anonymous: Anonym
  button:
    after_authenticate: bitte zuerst.
    authenticate: Authentifiziere dich
    take: Schicht annehmen
  not_found:
    text: Diese Anfrage um die Schicht zu wechseln konnte nicht gefunden werden.
    title: Öh
  title: Schicht übernehmen von {name}
help_out_shift:
  already_claimed:
    text: Diese Schicht ist mittlerweile komplett besetzt
  not_found:
    text: Diese Schicht gibt es nicht.
  title: Last Minute Schicht
  you_already_claimed: Du bist für diese Schicht bereits angemeldet.
history:
  category:
    discount: Rabatt verändert
    paid: Bezahlt
    paused: Pausierung
    registration: Anmeldung
    shift:cancelled: Von Schicht abgemeldet
    shift:completed: Schicht gearbeitet
    shift:enrolled: In Schicht eingetragen
    shift:no-show: Nicht zur Schicht erschienen
    suspended: Stilllegung
    unpaused: Mitgliedschaft fortgesetzt
    unsuspended: Mitgliedschaft reaktiviert
  info:
    discount: Verändert zu {info}
    paid: 'Total: {info} €'
    paused: ''
    shift:cancelled: für eine Schicht am {info}
    shift:completed: 'eingetragen am: {info}'
    shift:enrolled: für eine Schicht am {info}
    suspended: Du hattest keine Schicht gearbeitet
    unpaused: ''
history_data:
  label: Geschichte
information:
  loading: Lädt gerade ...
intro:
  bad_connection: Netzwerkfehler. Bitte versuche es erneut.
  button:
    request-password: Passwort wiederherstellen
    reset-password: Passwort speichern
  desc: Wähle deinen Dienst
  dynamic-route: Demo einer dynamischen Route
  hi: Hi, {name}!
  invalid_email: Die Email-Adresse ist nicht gültig.
  invalid_password: Passwort ist nicht gültig.
  new-password: Neues Passwort
  no_password_yet: >-
    Bist du schon länger Mitglied und hast noch kein Passwort? Klicke auf
    "Passwort vergessen" und erstelle ein Passwort für deinen Account.
  password_policy: Ein neues Passwort muss ein Länge von mindestens 10 Zeichen haben.
  server_down: Wahrscheinlich haben wir gerade Serverprobleme.
  unknown_error: Ein unbekannter Fehler ist aufgetreten.
  whats-your-email: Deine E-Mail-Adresse
  whats-your-password: Passwort
  wrong_credentials: E-Mail oder Passwort sind nicht korrekt.
join:
  address_card:
    city: Stadt
    first_name: Vorname
    last_name: Nachname
    mobile: Handynummer
    street_address: Straße und Straßennummer
    zip: Postleitzahl
  back_to_sign_in: Log-In
  button:
    below_pay:
      cancel_right: 14 Tage Widerrufsrecht
    go: Weiter
    pay: Bezahlen und robinhood store unterstützen
    register: Kostenlos Mitglied werden
    resume: Registrierung fortsetzen mit diesem Account
  email_not_unique: Email bereits registriert.
  error_button: Schließen
  error_text: >-
    Bei deiner Anmeldung ist ein Fehler aufgetreten.<br>{0}<br><br>Du kannst uns
    helfen indem du eine E-Mail an contact_at_robinhood.store mit Nennung dieser
    ID schickst:<br>{1}
  logged_in:
    currently_logged_in: Gerade angemeldet als
    logout: Abmelden
    new_account: Neuer Account ohne weitere Daten
  membership_card:
    membership_options:
      options: >-
        Ich kann nicht im Laden helfen, z.B. aus gesundheitlichen Gründen oder einer besonderen Belastung im Alltag, wie z.B. zu pflegende Angehörige, alleinerziehend oder ähnliches - natürlich nach Selbsteinschätzung. Deswegen möchte ich mit einem Prozent meines Einkommens pro Monat das Projekt unterstützen. (Bis vor kurzem war der Geldbeitrag auch als Standardvariante auswählbar, aber das haben wir jetzt geändert, um das gedeihen und wachsen dieser Idee auf eine nachhaltige Menge an Schultern zu verteilen)
    membership_explanation: Du hilfst drei Stunden pro Monat im Laden mit.
    monthly_payment_number_error: Bitte gib einen Betrag höher als 7 € ein.
  newsletter:
    help: Etwa 4x im Jahr, zu wesentlichen und strukturellen Updates
    label: >-
      Ich möchte den Newsletter bekommen (etwa 4x im Jahr, zu wesentlichen und
      strukturellen Updates).
  or: Abbrechen und zurück zum
  password:
    error_length: Dein Passwort muss mindestens 10 Zeichen lang sein.
    help: >-
      Dein Passwort sollte einmalig sein. Benutze am besten einen
      Passwort-Manager.
  question:
    credit:
      soli:
        help: >-
          Dein Guthaben ist ein wichtiger Beitrag zu unserem Fortbestehen. Falls
          dies eine zu hohe Ausgabe für dich ist, deaktiviere das Guthaben mit
          diesem Kontrollkästchen. Wir haben dich trotzdem gerne dabei.
        label: Ohne Guthaben Mitglied werden
        title: Soli-Mitgliedschaft
      status:
        how:
          paying: >-
            Die Einzahlung nehmen wir gleichzeitig mit deiner ersten monatlichen
            Abbuchung per SEPA Lastschrift vor.
          working: Die Einzahlung geschieht über SEPA Lastschriftmandat.
        info: >-
          Du zahlst einmalig 60 €. Dafür bekommst du 12 Monate lang zum Anfang
          des Monats 5 € Guthaben im robinhood store.
        no_credit: Du hast dich entschieden ohne Guthaben Mitglied zu werden.
      title: Guthaben
    membership:
      monthly_payment: Monatlicher Beitrag in €
      monthly_payment_placeholder: 1% deines monatlichen Nettoeinkommens
      screenreader: Wähle die Konditionen deiner Mitgliedschaft.
      title: Du hilfst drei Stunden im Monat im Laden mit
    payment:
      address_line_1: Straße und Hausnummer
      address_line_2: zusätzliche Adressangaben
      bic: BIC
      city: Stadt
      country: Land
      first_name: Vorname
      iban: IBAN
      info: >-
        Wir ziehen deinen monatlichen Beitrag von {chooseMonthlyPayment} € per
        SEPA Lastschrift ein.
      last_name: Nachname
      mobile:
        help: >-
          Wenn du Schichten arbeitest, benötigen wir deine Nummer damit wir dich
          erreichen können.
        label: Telefon
      sepa:
        help: >-
          Hiermit ermächtige ich die Steib, Katz, Barthel GbR, Zahlungen von
          meinem Konto per Lastschrift einzuziehen. Ich weise mein
          Kreditinstitut an, die von Steib, Katz, Barthel GbR erteilten
          Lastschriften einzulösen. Hinweis: Ich kann innerhalb von acht Wochen,
          beginnend mit dem Belastungsdatum, die Erstattung des belasteten
          Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut
          vereinbarten Bedingungen. Die Frist für die Vorabinformation der
          SEPA-Lastschrift wird auf drei Tage verkürzt.
        info: >-
          robinhood store wird betrieben durch die Steib, Katz, Barthel GbR,
          Altenbraker Str. 15, 12053 Berlin; Gläubiger-Identifikationsnummer:
          DE94ZZZ00002372740
        label: SEPA-Lastschriftmandat erteilen
        modal_close_button: Schließen
      title: Monatlicher Beitrag
      zip: PLZ
    store:
      screenreader: Wähle bei welchem Laden du mitmachen willst.
      title: Bei welchem Laden möchtest du mitmachen?
    weserstrasse:
      info: >-
        Herzlich Willkommen bei ROBIN's Weserstrasse! 🎉 Wir freuen uns, dir
        mitteilen zu können, dass unsere neue Filiale am 10. Juni aufmacht! Auch
        wenn du dich jetzt schon anmeldest beginnt deine Mitgliedschaft
        offiziell erst am Eröffnungstag. Dein erster Beitrag oder die Abbuchung
        deines Guthabens werden also erst Ende Juni erfolgen. Wir können es kaum
        erwarten, dich als Teil unserer Community begrüßen zu dürfen, und wir
        freuen uns darauf, dich bald im Laden zu sehen! 😊
  success_button: Weiter zur ShiftApp
  success_text: >-
    ⏳ Wir schicken dir gerade eine Bestätigungs-Mail.<br /> Schau dich um auf <a
    href="https://wiki.robinhood.store">wiki.robinhood.store</a> für die
    nächsten Schritte.
  summary:
    membership:
      paying:
        credit: >-
          Dein Guthaben von {chooseCredit} € wird dir in 12 Monatsraten an der
          Kasse zur Verfügung gestellt.
        info: >-
          Du bezahlst monatlich {chooseMonthlyPayment} € und bekommst dafür 15 %
          Rabatt auf deine Einkäufe. Deine Mitgliedschaft wird am Ende des
          Monats automatisch verlängert. Der Rabatt ist nicht übertragbar. Wenn du mehr (oder weniger) als 15% Rabatt benötigst, kannst du das jederzeit unter shift.robinhood.store einstellen.
      working:
        credit: >-
          Dein Guthaben von {chooseCredit} € wird dir in 12 Monatsraten an der
          Kasse zur Verfügung gestellt.
        info: >-
          Nach einer Mithelfschicht in einem robinhood store erhältst du für die
          nächsten 30 Tage auf deine Einkäufe 15 % Rabatt. Der Rabatt ist nicht
          übertragbar. Wenn du mehr (oder weniger) als 15% Rabatt benötigst, kannst du das jederzeit unter shift.robinhood.store einstellen.
    newsletter:
      title: Newsletter
    terms_and_cancel:
      cancellation:
        link: Widerrufsrecht
        text: >-
          Du kannst deine Vertragserklärung innerhalb von 14 Tagen widerrufen.
          Weitere Informationen zu deinem
      intro: >-
        Diese Vertragsbedingungen gelten bei deiner robinhood store
        Mitgliedschaft:
      privacy: Datenschutzhinweise
      terms: Allgemeine Geschäftsbedingungen
      title: Allgemeine Geschäftsbedingungen und Widerruf
  tabs:
    account: Login
    address: Adresse
    membership: Mitgliedschaft
    payment: Bezahlung
    verify: Zusammenfassung
  title: Robinhood beitreten
login:
  error: ❤️‍🩹 Sorry. Ein Fehler ist aufgetreten. Gehe zum
  not_valid: 🚫 Der Link ist nicht mehr gültig.
  redirecting: ' 👍 Angemeldet. Leite weiter...'
  success: ' 👍 Logged in. Du kannst dieses Fenster schließen.'
menu_actions:
  request_double_tap: Ja, bestätigen
my_combo_box:
  loading: Lädt gerade
  no_results: Keine Resultate
  start_typing: Brauche mehr Buchstaben
not-found: Nicht gefunden
pagination:
  input:
    current_page: Aktuelle Seite
    first_page: erste Seite
    last_page: letzte Seite
    next_page: nächste Seite
    previous_page: vorherige Seite
planner:
  button:
    add: Add shift
    cant: Ich kann vorläufig keine Schichten übernehmen
    cant_dismiss: Rückgängig
    save:
      button: Speichert automatisch
      label: Schichten
      of: von
      success: Gespeichert
  cant:
    body: >-
      Das ist Schade, aber ok. Du kannst deine Schicht im nächsten Monat
      nachholen.
    title: Diesen Monat geht's nicht?
  delete_shift:
    button: Löschen
    title: Schicht Löschen?
  intro: Klicke auf einen Tag und wähle deine bevorzugte Schicht.
reset_password:
  cancel: Zurück zum Login
  error-message: >-
    Wir konnten deine E-Mailadresse nicht finden, weil du dich wahrscheinlich
    mit einer anderen E-Mailadresse angemeldet hast.
  submit-message: >-
    Wenn uns deine E-Mailadresse bekannt ist haben wir dir gerade eine Email mit
    weiteren Anweisungen geschickt. <br /> Die Email könnte auch in deinem Spam
    Ordner gelandet sein. <br /><br /> Du kannst dieses Fenster schließen.
  title: Passwort wiederherstellen
scanner:
  amount_select:
    label: neuer Bestand
    text: >-
      Als letztes musst du bitte noch auswählen, wie viel von dem Produkt noch
      vorhanden ist.
    unit: Stück
  button:
    info: Bestandsaufnahme abschließen
    text: Bestätigen
  confirm_modal:
    button: weiter
    text: Deine Bestandsaufnahme wurde erfolgreich gespeichert.
    title: Vielen Dank!
  error_modal:
    default:
      text: >-
        Da ist wohl etwas schief gelaufen... <br />Bitte versuche es gleich
        nochmal.
      title: Oh Mist!
    scan:
      text: >-
        Leider konnten wir diesen Barcode nicht identifizieren. <br> Bitte
        versuche es nochmal.
      title: Oh Mist!
  heading:
    sub_text: Hier geht es weiter mit deiner Bestandsaufnahme
  product_description:
    current_amount: alter Bestand
    heading: Produktinformation & Bestätigung
    name: Produktname
    sub_heading: >-
      Hier kannst du dir die Information zum ausgewählten Produkt anschauen und
      die Bestandsuafnahme bestätigen.
  product_select:
    ean:
      placeholder: EAN
      text: Gib hier die EAN Nummer ein.
    label: Ausgewähltes Produkt
    product_name:
      placeholder: Produktname
      text: Gib hier den Namen des Produktes ein.
  search_type_radio:
    label: Benutzart
    options:
      ean: nach EAN code suchen
      product_name: nach Produktnamen suchen
      scanner: EAN code scannen
    text: >-
      Du kannst entweder einen Barcode scannen oder nach der EAN Nummer
      beziehungsweise dem Produktnamen suchen.
  store_select:
    label: Bitte wähle deinen Laden aus
    text: Für welchen Laden machst du eine Bestandsaufnahme?
settings:
  application:
    choose_language: Wähle eine Sprache
    description: Stelle deine Vorlieben für die robinhood Anwendung ein.
    title: Anwendung
  danger:
    finalize: Ja, Mitgliedschaft beenden
    no_danger: Speichern
  danger_zone:
    description: Hier kannst du deine Mitgliedschaft beenden
    sections:
      cancel_membershift:
        title: Meine Mitgliedschaft beenden
    title: Gefahrenzone
  error_modal:
    description: >-
      Ein Fehler ist beim Speichern deiner Einstellungen passiert. Bitte erzähle
      uns davon. Error Code: 8420.
    title: Ups!
  history:
    description: Bekomme Einsicht in den Verlauf deiner Mitgliedschaft
    hide_button: Verberge vollständigen Verlauf
    title: Verlauf
    view_button: Zeige vollständigen Verlauf
  input:
    calendar: Kalender
    calendar_sync:
      button_copy: Kopiere Kalender Adresse
      button_copy_done: In Zwischenablage kopiert
      description: >-
        Abonniere deine Schichten in Kalenderanwendungen auf deinem Handy oder
        PC. Achtung: Du erstellst damit einen Link, den du nur mit Anwendungen
        teilen solltest, denen du vertraust.
      instructions: >-
        Wie gehts? <a
        href="https://support.google.com/calendar/answer/37100?hl=de&co=GENIE.Platform%3DAndroid"
        target="_blank">Android</a> &#124; <a
        href="https://support.apple.com/de-de/HT202361" target="_blank">Apple
        Geräte</a>
      title: Kalenderverknüpfung
    cancel_membership:
      description: >-
        Beachte: Gemäß unserer AGB kann robinhood store dir verbleibendes
        Guthaben nicht früher auszahlen.
      title: Meine Mitgliedschaft kündigen
    change_password:
      button: Passwort verändern
      title: Passwort
    city:
      title: Stadt
    country:
      title: Land
    discount:
      description: >-
        Wähle einen Wert zwischen -20% (du bezahlst mehr) und 20% (du bezahlst
        weniger). Rabatte von mehr als 15% sind ausdrücklich für Menschen in
        finanziellen Schwierigkeiten.
      notification_less_0: Du bezahlst obendrauf
      notification_more_15: Wenn du dich in finanziellen Schwierigkeiten befindest
      title: Rabatt
      validation_note: Der Rabatt muss ein Betrag zwischen -20 und 20 sein.
    email:
      cancel_change_button: Änderung abbrechen
      description:
        pending: >-
          Wir haben dir eine Email geschickt mit einem Link um deine neue
          Emailadresse zu verifizieren. Der Link ist 24 Stunden gültig.
        verified: >-
          Wir schicken dir eine Email mit einem Link um deine neue Adresse zu
          verifizieren. Dieser Link ist 24 Stunden gültig.
      title: E-Mail Adresse
    interests:
      title: Interessen
    language: Language
    name:
      title: Name
    password:
      description: >-
        Du bekommst eine Email mit weiteren Anleitungen wie du dein Password
        wiederherstellen kannst.
    pause:
      button: Mitgliedschaft pausieren
      description: >-
        Das heißt, dein Schichtkonto kann momentan mehr wachsen und du bekommst
        keinen Rabatt mehr an der Kasse. Wenn du deinen Account wieder
        aktivieren möchtest, kann es bis zu 24 Stunden dauern, bis du wieder
        einen Rabatt an der Kasse erhältst.
      description_if_no_shifts: >-
        Wir haben deinen Account pausiert weil du in letzter Zeit keine
        Schichten erledigt hast. Sobald du eine Schicht gearbeitet hast, wirst
        du automatisch aktiviert. Es kann allerdings bis zu 24 Stunden dauern
        bis du in den Läden wieder deinen Rabatt erhältst.
      description_will_auto_unpause: >-
        Dein Account ist noch pausiert. Du kannst deinen Account jetzt manuell
        aktivieren oder warten bis es automatisch passiert. Bitte beachte, es
        kann bis zu 24 Stunden dauern, bis du deinen Rabatt an der Kasse wieder
        bekommst.
      paused_status: >-
        Dein Account ist momentan pausiert. Wenn du deinen Account aktivierst,
        kann es bis zu 24 Stunden dauern, bis du an der Kasse wieder deinen
        Rabatt erhältst.
      title: Pausierung
      upaused_status: >-
        Dein Account ist momentan aktiv. Wenn du deinen Account pausierst,
        bekommst du keinen Rabatt mehr in unseren Läden.
    pause_reminder:
      description: >-
        Ich werde immer benachrichtigt, wenn mein Account pausiert wurde weil
        ich keine Schichten gearbeitet habe.
      title: Automatische Pausierung / Aktivierung
    phone:
      title: Handynummer
    reminder_one_week:
      description: Erinnere mich 7 Tage vor meiner nächsten Schicht.
      title: 'Erinnerung: Eine Woche davor'
    reminder_two_days:
      description: Erinnere mich 2 Tage vor meiner nächsten Schicht.
      title: 'Erinnerung: Zwei Tage davor'
    street:
      title: Straße
    tips: Tipps
    tooltip:
      description: >-
        Neue Mitglieder sehen zusätzliche Informationen wie sie die Shift App
        benutzen können.
      title: Wie trage ich mich zu Schichten ein
    unpause:
      button: Mitgliedschaft fortsetzen
    zip:
      title: Postleitzahl
  membership:
    description: Allgemeine Einstellungen für deine robinhood Mitgliedschaft
    title: Mitgliedschaft
  memership:
    reset_button: Zurücksetzen
    save_button: Speichern
  notifications:
    description: Entscheide welche Benachrichtigungen du erhalten möchtest.
    save_button: Speichern
    title: Benachrichtigungen
  personal_information:
    save_button: Speichern
    title: Persönliche Daten
settiwgs:
  input:
    language:
      title: Sprache
shift_modal:
  button:
    cancel: Abbrechen
    close: Schließen
    submit: Speichern
  duration: 3 Stunden
  enroll_for_shift: Schicht annehmen
  enrolled_button: Angenommen
  important_shift: Wichtige Schicht
  more_shift_info: Infos zur Schicht
  other_volunteer_count: Schicht gelöscht | alleine | {n} Freiwillige
  swap:
    button_cancel: Wechsel abbrechen
    button_copy: Kopiere Link
    help_out: Hilf einem anderen Mitglied und übernehme diese Schicht.
    reason: 'Begründung:'
    stay_put: >-
      Ein anderes Mitglied kann zu dieser Schicht nicht mehr und probiert Ersatz
      zu finden. Danke, dass du dabei bist.
    yours_ongoing: Andere Mitglieder sehen hier deine Anfrage um die Schicht zu übernehmen.
  swap_notice: Ein Mitglied kann nicht zur Schicht und sucht Ersatz
  title: Wähle deine Schicht für {day} – {date}
shift_profile:
  button:
    close: Fenster schließen
  text_header: Was gibts zu tun?
  title: 'Schicht Info: {type}'
  type_not_exist: >-
    Diese Schicht hat noch keine genaue Anleitung. Wir werden dir alles vor Ort
    erklären.
shift_tile:
  cancel: Absagen
  description: Hinweis
  reason: '{name} gibt als Grund an'
  start_swap: Wechsel anstoßen
signin:
  call_to_join: registriere dich und werde Mitglied
  desc: Benutze die Emailadresse mit der du dich bei robinhood angemeldet hast.
  or: oder
  request_password: Passwort vergessen?
  title: shift Anmeldung
sr-only:
  select:
    select: Auswahl
    shift: Art der Arbeit
    unavailable: Grund für Fehlen
store_select:
  label: Standort
  prompt: Wähle einen Laden
success:
  info:
    button_editor: Bearbeite deine Schichten
    late: >
      Schichtwechsel in letzter Minute sind doof für Robin. Aber wenn dir in
      letzter Sekunde etwas dazwischen kommt, sag bitte im Laden Bescheid<br />
      <b>☎️ 01775 00 74 87</b> (09:30 - 20:00)
    text_4_days: >
      <p>Wenn du tauschen musst und deine Schicht mehr als 4 Tage entfernt ist
      trage deine Schicht einfach selbständig um.</p>
    text_less_days: >
      <p>Sind es nur noch 4 Tage oder weniger bis zu deiner Schicht, klicke
      bitte auf den "Wechsel anstoßen" Knopf im grünen Schichtviereck um die
      Schicht frei zu machen für andere Mitglieder. Dann frage bitte in der
      Telegram Gruppe nach, ob eine Person sie übernehmen kann. Klärt dies dann
      über einen privaten Chat.</p>

      <p>Sollte sich keine Person bis 1 Tag vorher finden, melde dich bitte beim
      Telefondienst&nbsp;☎️️&nbsp;030&nbsp;814&nbsp;570&nbsp;259</p>
    title: Falls du Absagen musst
  text: 'Momentan nichts weiter geplant | Dein nächster Job: | Deine nächsten Jobs: '
  text_nojobs: >-
    <p>Du hast dich vorläufig noch für keine Schichten angemeldet. Wenn dir
    wieder mehr danach ist, sei so frei. 😘</p>
  title: 🎉 Vielen Dank
  title_nojobs: Alles klar,
suschi:
  modal_day_view:
    add_member:
      placeholder: Gib einen Namen ein
    slot_actions:
      cancel_shift: Mitglied löschen
      late_show: Late-Show melden
      no_show: No-Show melden
      start_swap: Wechsel anstoßen
  no_store_error: Keine Zugangsrechte
swap:
  button:
    cancel: Abbrechen
    details: mehr
    submit: Anfrage abschicken
  copied:
    info: Link in die Zwischenablage kopiert
  modal_intro: >-
    Kurzfristig absagen ist nicht erlaubt weil Schichten dann oft leer bleiben.
    Bitte finde Ersatz für deine Schicht <b>{type}</b> am <b>{date}</b> um
    <b>{time}</b>.<br /><br /> Z.B. frage in unserer <a
    href="https://t.me/joinchat/Ivq6LsRh2xM1YjQy" target="_blank">Telegram
    Gruppe</a>. 🎙
  modal_title: Wechsel anstoßen
  prompt_reason: Mit einem Grund fällt es anderen leichter deine Schicht zu übernehmen.
  reason_placeholder: optionale Begründung (max. 100 Zeichen)
  share:
    text: Frage andere Mitglieder ob sie kurzfristig deine Schicht übernehmen können
    title: Bitte Schicht übernehmen
tabs:
  select_prompt: Wähle einen Tab
temp:
  new_rules: '    🎉 Endlich bessere Regeln für arbeitende Mitglieder.<br>     Erfahre mehr in der <a href="https://wiki.robinhood.store/Newsletter-September-2022-58e2f5b31e0d4f1ea19fb51fca885463" target="_blank">Ankündigung</a>.'
time:
  select:
    label: Zeitrahmen
tooltip:
  button:
    proceed: Alles klar
  enroll_shifts:
    text: >-
      Sollte dir ein Termin dazwischen kommen, kannst du deine Schichten
      absagen. Bis zu 4 Tage bevor die Schicht statt findet. Danach musst du
      Ersatz für die Schicht finden und wir erwarten dich im Laden, wenn das
      nicht gelingt.
    title: Wie gehts los?
  header:
    friday: F
    monday: M
    saturday: S
    sunday: S
    thursday: D
    tuesday: D
    wednesday: M
  hide_future: Verberge diese Hinweise in der Zukunft
  tabs:
    calendar_overview:
      evening: abends
      morning: morgens
      noon: mittags
      text: >-
        Finde schnell verfügbare Schichten indem du dich an den drei Punkten
        orientierst. In diesem Beispiel weist der pinke Punkt auf verfügbare
        Schichten am Abend.
      title: Schichten eintragen
    cancel_conditions:
      4_days_ahead: 4 Tage davor
      shift: Schicht
      text: >-
        Du kannst Schichten bis zu 4 Tage vor der Schicht absagen. Wenn es
        kurzfristiger wird, finde eine Person, die für dich einspringt indem du
        einen Wechsel anstößt.
      title: Schichten absagen
    pausing_conditions:
      text: >-
        Nach einer Schicht bleibt dein Account 30 Tage aktiv. Um eine
        automatische Pausierung zu verhindern, arbeite rechtzeitig eine weitere
        Schicht.
      title: Bleibe aktiviert
upcoming:
  action:
    sign_up_shift: Neue Schicht eintragen
  actions:
    admin:
      description: Erstelle, bearbeite und lösche Schichten
      title: Admin
    editor:
      no_next_shift: Momentan hast du keine Schichten geplant.
      prefix_distance: 'Nächste Schicht:'
      title: Schichtplan
      title_no_member: Arbeite Schichten
    history:
      title: Verlauf
    scanner:
      description: >-
        Die unkomplizierte Inventur für robinhood store – hier kannst du bei
        einer Bestandsaufnahme helfen
      title: Scanner
    settings:
      description: Bearbeite wichtige Einstellungen deiner robinhood store Mitgliedschaft
      title: Einstellungen
    suschi:
      description: >-
        Bekomme einen detallierten Wochenüberblick der Schichten. Außerdem
        kannst du Mitglieder in Schichten ein- und austragen.
      title: Verwalte Schichten
    suschi_wiki:
      description: >-
        "One of the great things about books is sometimes there are some
        fantastic pictures." – G. W. Bush
      title: Suschi Wiki
    upcoming:
      title: Übersicht
  barcode:
    dismiss: Drücke irgendwo um zu verbergen.
    sr_only_dismiss: Drücke Escape um den Barcode zu verbergen.
  editor:
    next:
      day_after_tomorrow: übermorgen
      today: heute
      tomorrow: morgen
  title: Übersicht
  widget_active_membership:
    active_for_days: In {distance}.
    active_membership: Aktive Mitgliedschaft
    active_until: Dein Account bleibt aktiv bis zum {date}
    extra_days: >-
      Wenn du deinen Account wieder aktivierst, wird dein Account weitere {
      count } Tage aktiv bleiben.
    no_extra_days: Nachdem du eine Schicht gearbeite hast wird dein Account wieder aktiviert.
    not_active: Dein Account ist pausiert.
    paused_membership: Pausierte Mitgliedschaft
    puppy_protection: >-
      Du kannst deinen Account aktivieren. Aber erledige eine Schicht bis zum
      15. Oktober, wenn du willst, dass dein Account danach aktiv bleibt.
verify-email:
  close_prompt: Du kannst dieses Fenster schließen oder gehe zu
  error: >-
    Konnte deine Email-Adresse nicht verifizieren. Vielleicht weil dein
    Einmalkennwort abgelaufen ist.
  pending: Verifiziert gerade deine neue Email-Adresse
  settings_link: deinen Benutzereinstellungen
  success: Neue Email-Adresse erfolgreich verifiziert.
