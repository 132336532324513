<script setup lang="ts">
import { useHead } from '@vueuse/head'

useHead({
  title: 'robinhood:schichtplan',
  meta: [
    { name: 'description', content: 'Dein Schichtplan bei Robinhood' },
  ],
})
</script>

<template>
  <router-view />
</template>
