/*
  Load config env vars during run time.
  Requires a entrypoint.sh with `chmod +x entrypoint.sh`
  ```
  #!/bin/sh
  JSON_STRING='window.configs = { \
    "SHIFTAPP_API_BASE":"'"${SHIFTAPP_API_BASE}"'", \
    "SHIFTAPP_SITE_BASE":"'"${SHIFTAPP_SITE_BASE}"'" \
  }'
  sed -i "s@// SHIFTAPP_CONFIGURATIONS_PLACEHOLDER@${JSON_STRING}@" /usr/share/nginx/html/index.html
  exec "$@"
  ```

  See more info at: https://medium.com/js-dojo/vue-js-runtime-environment-variables-807fa8f68665

  Deploy by adding
  ```
  COPY entrypoint.sh /docker-entrypoint.d/
  RUN chmod +x /docker-entrypoint.d/entrypoint.sh
  ```
  to Dockerfile at the nginx serving stage.
*/

export function getEnv(name: string) {
  if (import.meta.env.PROD) {
    return window?.configs?.[name] || process.env[name]
  }
  return import.meta.env[name]
}
